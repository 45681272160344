<template>
<div>

</div>
</template>

<script>
import ProductsService from "@/services/products.service";

export default {
  name: "productUpdate",
  async mounted(){
    const products = await ProductsService.getAllProductsFromBase();
    console.log(products);
    for(let i = 0;i<products.length;i++){
      const menuProduct = products[i];
      if(i === products.length-1){
        console.log(menuProduct);
      }
    }
  }
}
</script>

<style scoped>

</style>